import React from "react"

const Other = () => {
    return (
        <div className="skills__content">
            <div className="skills__title">Database & Other Tools</div>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i class='bx bx-badge-check' ></i>

                        <div>
                            <h3 className="skills__name">SQL</h3>
                            <span className="skills__level">Advanced</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class='bx bx-badge-check' ></i>

                        <div>
                            <h3 className="skills__name">GitHub</h3>
                            <span className="skills__level">Advanced</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class='bx bx-badge-check' ></i>

                        <div>
                            <h3 className="skills__name">Docker</h3>
                            <span className="skills__level">Beginner</span>
                        </div>
                    </div>
                </div>

                <div className="skills__group">
                    <div className="skills__data">
                        <i class='bx bx-badge-check' ></i>

                        <div>
                            <h3 className="skills__name">Agile</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class='bx bx-badge-check' ></i>

                        <div>
                            <h3 className="skills__name">Figma</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class='bx bx-badge-check' ></i>

                        <div>
                            <h3 className="skills__name">Linux</h3>
                            <span className="skills__level">Beginner</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Other
