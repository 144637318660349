import React from "react"

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://www.linkedin.com/in/wakil18" className="home__social-icon" target="_blank">
                <i class="uil uil-linkedin-alt"></i>
            </a>
            
            <a href="http://m.me/wakil.ahmed.581" className="home__social-icon" target="_blank">
                <i class="uil uil-facebook-messenger-alt"></i>
            </a>
            
            <a href="https://github.com/Wakil18" className="home__social-icon" target="_blank">
                <i class="uil uil-github-alt"></i>
            </a>
        </div>
    )
}

export default Social
