import React from "react"
import "./skills.css"
import Language from "./Language"
import Backend from "./Backend"

const Skills = () => {
    return (
        <div className="skills section" id="skills">
            <h2 className="section__title">Skills</h2>
            <span className="section__subtitle">My Technical Skills</span>

            <div className="skills__container container grid">
                <Language />
                <Backend />
            </div>
        </div>
    )
}

export default Skills
