import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
    {
        id: 1,
        image: Image1,
        title: "Walter White",
        description: "Lorem Ipsum is Lorem Ipsum and it is Lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lore lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lorem Ips"
    },
    {
        id: 2,
        image: Image2,
        title: "Holly Molly",
        description: "Lorem Ipsum is Lorem Ipsum and it is Lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lore lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lorem Ips"
    },
    {
        id: 3,
        image: Image3,
        title: "Ada Shelby",
        description: "Lorem Ipsum is Lorem Ipsum and it is Lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lore lorem Ipsum is Lorem Ipsum is Lorem Ipsum is Lorem Ips"
    }
];  
