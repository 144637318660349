import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
  const [toogleState, setToogleState] = useState(2);

  const toogleTab = (index) => {
    setToogleState(index);
  };

  return (
    <section className="qualification section">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My Personal Journey</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
                toogleState === 1
                    ? "qualification__button qualification__active button--flex"
                    : "qualification__button button--flex"
                }
            onClick={() => toogleTab(1)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>{" "}
            Education
          </div>

          <div
            className={
              toogleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toogleTab(2)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>{" "}
            Experience
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toogleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Designer</h3>
                <span className="qualification__subtitle">Spain Institute</span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> 2021 - present
                </div>
              </div>

              <div>
                <div className="qualification__rounder"></div>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <div className="qualification__rounder"></div>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Art Director</h3>
                <span className="qualification__subtitle">Spain Institute</span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> 2021 - present
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Development</h3>
                <span className="qualification__subtitle">Spain Institute</span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> 2021 - present
                </div>
              </div>

              <div>
                <div className="qualification__rounder"></div>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <div className="qualification__rounder"></div>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">UX Expert</h3>
                <span className="qualification__subtitle">Spain Institute</span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> 2021 - present
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toogleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Designer</h3>
                <span className="qualification__subtitle">Spain Institute</span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> 2021 - present
                </div>
              </div>

              <div>
                <div className="qualification__rounder"></div>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <div className="qualification__rounder"></div>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Art Director</h3>
                <span className="qualification__subtitle">Spain Institute</span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> 2021 - present
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Development</h3>
                <span className="qualification__subtitle">Spain Institute</span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> 2021 - present
                </div>
              </div>

              <div>
                <div className="qualification__rounder"></div>
                <span className="qualification__line"></span>
              </div>
            </div>

            {/* <div className="qualification__data">
                            <div></div>

                            <div>
                                <div className="qualification__rounder"></div>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">UX Expert</h3>
                                <span className="qualification__subtitle">Spain Institute</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calender-alt"></i> 2021 - present
                                </div>
                            </div>
                            
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
